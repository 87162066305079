<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <h5 slot="header" class="mb-0">
          <b-button
            size="sm"
            :to="{
              name: 'admin.customer-loan-accounts.detail',
              params: { id: this.$route.params.id },
            }"
            class="mr-2"
            ><i class="fa fa-chevron-left"></i> Буцах</b-button
          >
        </h5>
        <b-form @submit.stop.prevent="saveData">
          <h4 class="mb-0">Барьцаа</h4>
          <hr class="mt-2" />

          <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үйл ажиллагааны чиглэл"
                  label-for="input-spec"
                >
                  <b-form-select
                    id="input-spec"
                    v-model="deposit_women.depositSpec"
                    :options="depositSpecListWomen"
                    placeholder="Үйл ажиллагааны чиглэл"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatedSince"
                  label="Үйл ажиллагаа явуулж эхэлсэн он"
                  label-for="input-operatedSince"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_women.depositOperatedSince"
                    type="text"
                    required
                    placeholder="Үйл ажиллагаа явуулж эхэлсэн он"
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үйл ажиллагаа"
                  label-for="input-spec"
                >
                  <b-form-input
                    id="input-spec"
                    v-model="deposit_women.depositOperation"
                    placeholder="Үйл ажиллагаа"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-operatingLocation"
                  label="Үйл ажиллагааны байршил"
                  label-for="input-operatingLocation"
                >
                  <b-form-input
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_women.depositOperatingLocation"
                    type="text"
                    required
                    placeholder=""
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatingLocationType"
                  label="Үйл ажиллагааны байршил"
                  label-for="input-operatingLocationType"
                >
                  <b-form-select
                    id="input-operatingLocationType"
                    v-model="deposit_women.depositOperatingLocationType"
                    placeholder="Үйл ажиллагааны байршил"
                    :options="depositLocationType"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Банкны нэр"
                  label-for="input-savingBank"
                >
                  <b-form-select
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_women.depositSavingBank"
                    :options="depostiBankList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-savingBankAccount"
                  label="Дансны дугаар"
                  label-for="input-savingBankAccount"
                >
                  <b-form-input
                    id="input-savingBankAccount"
                    class="form-control"
                    v-model="deposit_women.depositSavingBankAccount"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-isSingleMother"
                  label="Өрх толгойлсон эсэх"
                  label-for="input-isSingleMother"
                >
                  <b-form-select
                    id="input-isSingleMother"
                    class="form-control"
                    v-model="deposit_women.isSingleMother"
                    :options="isSingleList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-childrenCount"
                  label="Хүүхдийн тоо"
                  label-for="input-childrenCount"
                >
                  <b-form-input
                    id="input-childrenCount"
                    class="form-control"
                    v-model="deposit_women.childrenCount"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

          <b-button type="submit" variant="success">Хадгалах</b-button>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  data: function() {
    return {
      isLoading: false,
      deposit_women: {
        depositSpec: "",
        depositOperatedSince: 0,
        depositOperation: "",
        depositOperatingLocation: "",
        depositOperatingLocationType: "",
        depositSavingBank: "",
        depositSavingBankAccount: "",
        isSingleMother: 0,
        childrenCount: ""
      },
      isSingleList: [
        { text: "Тийм", value: 1},
        { text: "Үгүй", value: 0}
      ],
      depositLocationType: [
        { text: "Түрээс", value: "Түрээс" },
        { text: "Өөрийн эзэмшлийн", value: "Өөрийн эзэмшлийн" },
      ],

      is_deposit_women: false,
      no_deposit_women_message: "Үйл ажиллагааны чиглэл сонгоогүй байна.",
      depositSpecListWomen: [
        { text: "Худалдаа", value: "Худалдаа" },
        { text: "Үйлчилгээ", value: "Үйлчилгээ" },
        { text: "Үйлдвэр", value: "Үйлдвэр" },
      ],
      depostiBankList: [
        { text: "Худалдаа Хөгжлийн Банк", value: "Худалдаа Хөгжлийн Банк"},
        { text: "Хаан Банк", value: "Хаан Банк"}
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_deposit_woman_data",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            this.$data.deposit_women = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    saveData: function() {
      this.isLoading = true;
      //console.log(this.$data.form)
      this.$http
        .post(
          this.$config.API_URL + "LoanWebService/create_deposit_woman",
          {
            loanAccountId: this.$route.params.id,
            id: this.$data.deposit_women.id != undefined ? this.$data.deposit_women.id : 0,
            data: JSON.stringify(this.$data.deposit_women),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response.body)
            if (response.body.success) {
              //console.log('asdf')
              this.$router.push({
                name: "admin.customer-loan-accounts.detail",
                params: { id: this.$route.params.id },
              });
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
